import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { EffectCreative, Autoplay, Pagination } from "swiper/modules";

import img1 from "../../Assets/achivments/achivement1.png";
import img2 from "../../Assets/achivments/achivement2.png";
import img3 from "../../Assets/achivments/achivement3.png";
import img4 from "../../Assets/achivments/achivement4.png";
import img5 from "../../Assets/achivments/achivement5.png";
import { motion } from "framer-motion";
const Achivement = ({ Element }) => {
  const style1 = {
    WebkitTextStroke: "1px #dee2e6",
  };
  return (
    <>
      <Element name="scroll-to-achivement" className="pt-10 md:pt-40 ">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.75, duration: 5 }}
          className="container px-6"
        >
          <div className="relative flex items-center justify-center mb-10 md:mb-20">
            <h1
              className="text-white dark:text-black  font-poppins font-base text-5xl md:text-7xl lg:text-8xl xl:text-9xl uppercase"
              style={style1}
            >
              Achivements
            </h1>
            <h4 className="text-orange-500 font-roboto text-lg md:text-2xl lg:text-3xl xl:text-4xl font-bold absolute uppercase">
              Performance
            </h4>
          </div>
          <Swiper
            autoplay={{
              delay: 4000,
            }}
            effect={"creative"}
            loop={true}
            pagination={true}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            modules={[Autoplay, EffectCreative, Pagination]}
            className="my-10"
          >
            <SwiperSlide>
              <img src={img1} className="w-full lg:w-4/6" alt="Vk_img" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} className="w-full lg:w-4/6" alt="Vk_img" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img3} className="w-full lg:w-4/6" alt="Vk_img" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img4} className="w-full lg:w-4/6" alt="Vk_img" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img5} className="w-full lg:w-4/6" alt="Vk_img" />
            </SwiperSlide>
          </Swiper>
        </motion.div>
      </Element>
    </>
  );
};

export default Achivement;
