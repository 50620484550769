export const basicInfoValue = [
  {
    id: 1,
    infoTitle: "Name",
    infoValue: "Prasad Yadav",
  },
  {
    id: 2,
    infoTitle: "Email",
    infoValue: "yadavprasad8500@gmail.com",
  },
  {
    id: 3,
    infoTitle: "Experience",
    infoValue: "3 Years",
  },
  {
    id: 4,
    infoTitle: "Degree",
    infoValue: "Master in Computer Science",
  },
];
