import React from "react";
import { motion } from "framer-motion";
const Experience = ({ Element }) => {
  const style1 = {
    WebkitTextStroke: "1px #dee2e6",
  };

  const fadeInLeftAnimationVariants = {
    initial: {
      opacity: 0,
      x: -100,
    },
    animate: () => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.7,
        duration: 2,
        type: "tween",
      },
    }),
  };

  const fadeInLRightAnimationVariants = {
    initial: {
      opacity: 0,
      x: 100,
    },
    animate: () => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.3,
        duration: 2,
        type: "tween",
      },
    }),
  };

  const fadeInLRight1AnimationVariants = {
    initial: {
      opacity: 0,
      x: 100,
    },
    animate: () => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.9,
        duration: 2,
        type: "tween",
      },
    }),
  };
  return (
    <>
      <Element name="scroll-to-experience" className="pt-10 md:pt-40">
        <div className="mx-4 md:mx-16">
          <div className="space-y-2 ">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.95, duration: 5 }}
              className="relative flex items-center justify-center mb-10 md:mb-20"
            >
              <h1
                className="text-white dark:text-black  font-poppins font-base text-5xl md:text-7xl lg:text-8xl xl:text-9xl uppercase"
                style={style1}
              >
                Experience
              </h1>
              <h4 className="text-orange-500 font-roboto text-lg md:text-2xl lg:text-3xl xl:text-4xl font-bold absolute uppercase">
                03 Experience
              </h4>
            </motion.div>
            {/* main  */}
            <div className="w-full max-w-3xl mx-auto py-0 md:py-10">
              <div className="space-y-4 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
                <motion.div
                  variants={fadeInLRightAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active left-0 md:left-[-16px] xl:left-[385px]"
                >
                  <div className="flex items-center w-10 h-10 rounded-full border border-orange-500 group-[.is-active]:bg-white text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"></div>
                  <div className="w-[calc(100%-4rem)] md:w-[calc(50%-3.5rem)]  xl:w-[calc(100%-2.5rem)] bg-orange-300 p-4 rounded border shadow">
                    <div className="flex items-center justify-between space-x-2 mb-1">
                      <p className="font-bold text-xl text-orange-500">
                        Web Developer Associate
                      </p>
                      <time className="text-slate-700 text-sm">
                        Mar 2022 - Present
                      </time>
                    </div>
                    <p className="text-slate-900 font-medium text-left text-base">
                      Accenture
                    </p>
                    <ul className="text-slate-700 text-left text-base mt-2 list-disc px-6">
                      <li>
                        Provided end-user support and training, addressing
                        technical issues and enhancing user proficiency with
                        Veeva Vault functionalities.
                      </li>
                      <li>
                        Assisted in the integration of Veeva Vault with other
                        enterprise systems, improving data flow and reporting
                        capabilities.
                      </li>
                      <li>
                        Designed and developed and administered eDetail
                        presentations using programming or scripting languages
                        such as Javascript and management systems such as Veeva
                        CRM
                      </li>
                      <li>
                        Managed end-to-end project delivery, including
                        initiation, planning, execution, resource allocation,
                        closure and Working with clients and Brand manager under
                        the guidance of senior project managers.
                      </li>
                      <li>
                        Designed and executed a document automation workflow
                        that synchronized updates across various
                        assets, enhancing document tracking and reducing
                        operational costs
                      </li>
                      <li>Received an Encore award in every quarter</li>
                    </ul>
                  </div>
                </motion.div>

                <motion.div
                  variants={fadeInLeftAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active right-0 md:right-[-16px] xl:right-[385px]"
                >
                  <div className="flex items-center justify-center w-10 h-10 rounded-full border border-orange-500 bg-slate-300 group-[.is-active]:bg-white text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"></div>
                  <div className="w-[calc(100%-4rem)] md:w-[calc(50%-3.5rem)]  xl:w-[calc(100%-2.5rem)] bg-orange-300 p-4 rounded border shadow">
                    <div className="flex items-center justify-between space-x-2 mb-1">
                      <p className="font-bold text-xl text-orange-500">
                        Frontend Developer
                      </p>
                      <time className="text-slate-700 text-sm">
                        Jan 2021 - Mar 2022
                      </time>
                    </div>
                    <p className="text-slate-900 font-medium text-left text-base">
                      Employee Forums
                    </p>
                    <ul className="text-slate-700 text-left text-base mt-2 list-disc px-6">
                      <li>
                        Designed and developed responsive web application with
                        API integration alongside rest framework in React Js.
                      </li>
                      <li>
                        Started with Nuxt.js, then studied React.js Selfly and
                        created a website similar to Linkedin.
                      </li>
                      <li>
                        Trained and managed Intern, offering continuous guidance
                        and mentorship while providing constructive feedback.
                      </li>
                    </ul>
                  </div>
                </motion.div>

                <motion.div
                  variants={fadeInLRight1AnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active left-0 md:left-[-16px] xl:left-[385px]"
                >
                  <div className="flex items-center justify-center w-10 h-10 rounded-full border border-orange-500  bg-slate-300 group-[.is-active]:bg-white text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"></div>
                  <div className="w-[calc(100%-4rem)] md:w-[calc(50%-3.5rem)]  xl:w-[calc(100%-2.5rem)] bg-orange-300 p-4 rounded border shadow">
                    <div className="flex items-center justify-between space-x-2 mb-1">
                      <p className="font-bold text-xl text-orange-500">
                        Assistant Developer
                      </p>

                      <time className="text-slate-700 text-sm">
                        Nov 2019 - May 2020
                      </time>
                    </div>
                    <p className="text-slate-900 font-medium text-left text-base">
                      Ocufox Technologies Pvt. LTD.
                    </p>
                    <ul className="text-slate-700 text-left text-base mt-2 list-disc px-6">
                      <li>
                        Developed Web Applications, website, and mobile
                        applications using Nuxt.js, flutter, and GraphQl. The
                        project is all about creating an application like Udemy.
                      </li>
                      <li>
                        Firebase fire cloud and Backend API integration and
                        learnt new Technologies.
                      </li>
                    </ul>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default Experience;
